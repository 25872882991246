import React from 'react';

interface LocalStorageStateProps {
  pollingEnabled?: boolean;
}
interface LocalStorageContextProps {
  localStorageState: LocalStorageStateProps;
  setLocalStorageState: (key: string, val: string) => void;
}
export const LocalStorageContext = React.createContext({} as LocalStorageContextProps);

interface LocalStorageProviderProps {
  children: React.ReactNode;
}
export const LocalStorageProvider: React.FC<LocalStorageProviderProps> = ({ children }) => {
  const [localStorageState, setLocalStorage] = React.useState({} as LocalStorageStateProps);
  const getStateFromLocalStorage = () => {
    const pollingEnabled = localStorage.getItem('pollingEnabled');
    setLocalStorage((p) => ({
      ...p,
      pollingEnabled: pollingEnabled === null || pollingEnabled === 'true',
    }));
  };
  const setLocalStorageState = React.useCallback((key: string, value: string) => {
    localStorage.setItem(key, value);
    if (key === 'pollingEnabled') {
      setLocalStorage((p) => ({
        ...p,
        pollingEnabled: value === 'true',
      }));
    }
  }, []);
  React.useEffect(() => {
    getStateFromLocalStorage();
  }, [localStorageState.pollingEnabled]);
  const value: LocalStorageContextProps = React.useMemo(
    () => ({
      localStorageState,
      setLocalStorageState,
    }),
    [setLocalStorageState, localStorageState],
  );
  return <LocalStorageContext.Provider value={value}>{children}</LocalStorageContext.Provider>;
};
