import React from 'react';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import { Box, Button, Dialog, Typography } from '@mui/material';
import { ActionsRequired } from '../../components/ActionsRequired';
import { AssetCard } from '../../components/AssetCard';
import { PrepareTransfer } from '../../components/PrepareTransfer';
import { RecentTransactions } from '../../components/RecentTransactions';
import { WalletPreview } from '../../components/WalletPreview';
import { useNeuchatelProxyApiClient } from '../../hooks/other/useNeuchatelProxyApiClient';
import { mockAssets } from '../../tests/mocks/mockAssets';

export const DashboardPage: React.FC = () => {
  const neuchatelApi = useNeuchatelProxyApiClient();
  const [name, setName] = React.useState('');
  const [openTransferForm, setOpenTransferForm] = React.useState(false);
  const onOpen = () => {
    setOpenTransferForm(true);
  };
  const onClose = () => {
    setOpenTransferForm(false);
  };
  React.useEffect(() => {
    neuchatelApi
      .getAppData()
      .then((res) => {
        setName(res.name);
      })
      .catch((e) => console.error(e));
  }, [neuchatelApi]);
  return (
    <Box sx={{ p: 4, display: 'flex', flexDirection: 'column', m: 1 }}>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mb: 4,
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography fontWeight="bold" variant="h4">
            Hello, {name}
          </Typography>
          <Typography variant="caption">Aug 27 2024</Typography>
        </Box>
        <Box sx={{ ml: 'auto' }}>
          <Button
            variant="contained"
            onClick={onOpen}
            sx={{
              mr: 1,
              textTransform: 'capitalize',
            }}
            startIcon={<AccountBalanceWalletOutlinedIcon />}
          >
            Connect a wallet
          </Button>
          <Button
            variant="contained"
            onClick={onOpen}
            sx={{
              textTransform: 'capitalize',
            }}
          >
            Make a transfer
          </Button>
        </Box>
      </Box>
      <ActionsRequired />
      <WalletPreview assets={mockAssets} />
      <br />
      <AssetCard name="Hashnote US Yield Coin" value={89000} symbol="USYC" />
      <RecentTransactions />
      <Dialog open={openTransferForm} onClose={onClose}>
        <PrepareTransfer onClose={onClose} />
      </Dialog>
    </Box>
  );
};
