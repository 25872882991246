import { AuthApi, ServerConfiguration, createConfiguration } from 'neuchatel-proxy-api-ts-client';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box, Typography, FormControlLabel, Checkbox } from '@mui/material';
import useBranding from '../../hooks/other/useBranding';
import { IDP_INFO_KEY, REMEMBERED_EMAIL_KEY } from '../../utils/constants';

export const IdpSelect: React.FC = () => {
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [remember, setRemember] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);

  const nav = useNavigate();
  const { branding } = useBranding();

  // TODO: Replace with config when we add hosted utilities to it
  const neuchatelUrl = `${window.location.origin}/api/neuchatel`;
  const configuration = createConfiguration({
    baseServer: new ServerConfiguration(neuchatelUrl, {}),
  });
  const client = new AuthApi(configuration);

  useEffect(() => {
    const rememberedEmail = localStorage.getItem(REMEMBERED_EMAIL_KEY);
    if (rememberedEmail) {
      setEmail(rememberedEmail);
      setRemember(true);
    }
  }, []);

  const fetchIdpInfo = () => {
    if (email) {
      if (remember) {
        localStorage.setItem(REMEMBERED_EMAIL_KEY, email);
      } else {
        localStorage.removeItem(REMEMBERED_EMAIL_KEY);
      }
      client
        .getIdpInfo(email)
        .then((i) => {
          localStorage.setItem(IDP_INFO_KEY, JSON.stringify(i));
          nav('/');
        })
        .catch(() =>
          setError(
            'We are not able to locate your organization. Please contact your administrator.',
          ),
        );
    }
  };

  const onKey = (key: string) => {
    if (key === 'Enter') {
      fetchIdpInfo();
    }
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: 2,
        boxSizing: 'border-box',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          maxWidth: 400,
        }}
      >
        {branding.companyLogo}
        <Typography variant="h3" sx={{ mt: 2, mb: 2 }}>
          Hosted Utilities
        </Typography>
        <Typography variant="body1" sx={{ mb: 4 }}>
          Sign In
        </Typography>
        <Box
          sx={{
            width: 450,
            minHeight: 285,
            padding: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            border: 1,
            borderColor: 'grey.300',
          }}
        >
          <Box sx={{ width: '100%', mb: 1 }}>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Email Address
            </Typography>
            <TextField
              variant="outlined"
              fullWidth
              name="email"
              sx={{ mb: 2 }}
              value={email}
              autoFocus
              onChange={(event) => setEmail(event.target.value)}
              onKeyDown={(event) => onKey(event.key)}
            />
            {error && (
              <Typography variant="body2" color="error">
                {error}
              </Typography>
            )}
          </Box>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={remember}
                onChange={(event) => setRemember(event.target.checked)}
              />
            }
            label="Remember me"
            sx={{ width: '100%', mb: 2 }}
          />

          <Button
            type="submit"
            variant="contained"
            fullWidth
            sx={{ mb: 1 }}
            color="primary"
            onClick={fetchIdpInfo}
          >
            Login
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
