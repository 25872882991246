import React from 'react';
import { Avatar, Box, Button, Card, ListItem, ListItemAvatar, Typography } from '@mui/material';
import { mockAssets } from '../tests/mocks/mockAssets';

interface AssetRowProps {
  symbol: string;
  amount: number;
  name: string;
}
const AssetRow: React.FC<AssetRowProps> = (props) => {
  const { symbol, amount, name } = props;
  return (
    <ListItem divider>
      <ListItemAvatar>
        <Avatar>{symbol[0]}</Avatar>
      </ListItemAvatar>
      <Box>
        <Typography color="textSecondary" variant="body2">
          {name}
        </Typography>
        <Typography>
          {amount} {symbol}
        </Typography>
      </Box>
    </ListItem>
  );
};
interface WalletPreviewProps {
  assets: { name: string; symbol: string; amount: number }[];
}
export const WalletPreview: React.FC<WalletPreviewProps> = ({ assets = mockAssets }) => {
  const renderedAssets = assets.map((a) => (
    <AssetRow key={a.symbol} name={a.name} amount={a.amount} symbol={a.symbol} />
  ));
  return (
    <Box>
      <Box
        sx={{
          mb: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography color="primary">Wallet A</Typography>
        <Button sx={{ ml: 'auto', textTransform: 'capitalize' }}>View Wallet</Button>
      </Box>
      <Card variant="outlined">{renderedAssets}</Card>
    </Box>
  );
};
