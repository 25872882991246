export const mockWallets = [
  {
    walletName: 'Wallet ABC',
    walletId: '1234',
  },
  {
    walletName: 'Wallet A',
    walletId: '456',
  },
  {
    walletName: 'Wallet B',
    walletId: '78',
  },
  {
    walletName: 'Wallet C',
    walletId: '9',
  },
  {
    walletName: 'Wallet with a really long annoying name',
    walletId: '19',
  },
];
