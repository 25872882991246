import React from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Button, Card, Collapse, Divider, Typography } from '@mui/material';

export interface TransferRequestRowProps {
  type: 'transfer';
  date: string;
  initiator: {
    name: string;
    address: string;
  };
  coSigners: string[];
  recipient: {
    name: string;
    address: string;
  };
  amount: number;
}
export const TransferRequestRow: React.FC<TransferRequestRowProps> = (props) => {
  const { type, date, initiator, coSigners, recipient, amount } = props;
  const [open, setOpen] = React.useState(false);
  const toggleOpen = () => {
    setOpen(!open);
  };
  const expandIcon = open ? <ExpandLess /> : <ExpandMore />;
  return (
    <Card
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        textTransform: 'capitalize',
        alignItems: 'flex-start',
      }}
    >
      <Box sx={{ p: 2, display: 'flex', flexDirection: 'row', width: '100%' }}>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography color="textSecondary">transfer</Typography>
          <Typography>{type}</Typography>
        </Box>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography color="textSecondary">date</Typography>
          <Typography>{date}</Typography>
        </Box>
        <Box
          sx={{
            maxWidth: 200,
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <Typography color="textSecondary"> Initiator</Typography>
          <Typography>{initiator.name}</Typography>
          <Typography sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {initiator.address}
          </Typography>
        </Box>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography color="textSecondary">Co-signers</Typography>
          <Typography>{coSigners.length} Others</Typography>
        </Box>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            width: '100px',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          <Typography color="textSecondary">Recipient</Typography>
          <Typography>{recipient.name}</Typography>
          <Typography sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {recipient.address}
          </Typography>
        </Box>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          <Typography color="textSecondary">Amount</Typography>
          <Typography>{amount}</Typography>
        </Box>
        <Box sx={{ ml: 'auto', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Button sx={{ mr: 1, textTransform: 'capitalize' }} variant="contained">
              Accept
            </Button>
            <Button sx={{ textTransform: 'capitalize' }} variant="outlined">
              Reject
            </Button>
          </Box>
          <Button
            sx={{ ml: 'auto', mt: 2 }}
            size="small"
            // variant="contained"
            onClick={toggleOpen}
            endIcon={expandIcon}
          >
            <Typography sx={{ textTransform: 'capitalize' }} variant="caption">
              View Detail
            </Typography>
          </Button>
        </Box>
      </Box>
      <Collapse sx={{ width: '100%' }} in={open}>
        <Box sx={{ width: '100%', p: 2 }}>
          <Divider sx={{ width: '100%' }} />
          <Box sx={{ mt: 2 }}>More fields here</Box>
        </Box>
      </Collapse>
    </Card>
  );
};
