import React from 'react';
import { Close } from '@mui/icons-material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {
  Box,
  Button,
  Card,
  IconButton,
  MenuItem,
  Radio,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material';
import { WalletsAccessTable } from './WalletsAccessTable';

const walletAccessOptions = [
  {
    value: 'Read-As',
    label: 'Read-As',
  },
  {
    value: 'Write-As',
    label: 'Write-As',
  },
];
const mockWallets = ['Wallet A', 'Wallet B'];
interface WalletAccessProps {
  name: string;
  walletRights: { walletId: string; walletName?: string; rights: string }[];
}
export const WalletAccess: React.FC<WalletAccessProps> = ({ name, walletRights }) => {
  const [isOpen, setOpen] = React.useState(false);
  const onToggle = () => {
    setOpen(!isOpen);
  };
  const [selectedAccess, setSelectedValue] = React.useState('read');
  const [wallet, setWallet] = React.useState('');
  const onSelectWallet = (event: SelectChangeEvent) => {
    setWallet(event.target.value);
  };
  const handleChange = (access: string) => {
    setSelectedValue(access);
  };
  const isAddDisabled = !wallet || !selectedAccess;

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
      <Typography>Wallets</Typography>
      <Typography color="textSecondary" sx={{ mb: 2 }} variant="caption">
        {name} does not have access to any wallets
      </Typography>
      {!isOpen && (
        <Button
          sx={{ textTransform: 'capitalize' }}
          onClick={onToggle}
          variant="outlined"
          startIcon={<AddCircleOutlineIcon />}
        >
          Assign to wallet
        </Button>
      )}
      {isOpen && (
        <Card variant="outlined" sx={{ p: 2 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Typography fontWeight="bold">Allow access to a wallet</Typography>
            <IconButton sx={{ ml: 'auto' }} onClick={onToggle}>
              <Close />
            </IconButton>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Typography>Select a wallet</Typography>
            <Select
              onChange={onSelectWallet}
              renderValue={(value: string) => {
                if (!value) {
                  return <Typography color="textSecondary">No wallet selected</Typography>;
                }
                return value;
              }}
              fullWidth
              displayEmpty
              value={wallet}
            >
              {mockWallets.map((w) => (
                <MenuItem key={w} value={w}>
                  {w}
                </MenuItem>
              ))}
            </Select>
          </Box>
          <Typography>Select access rights:</Typography>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {walletAccessOptions.map((a) => (
              <Box
                key={a.value}
                onClick={() => handleChange(a.value)}
                sx={{
                  cursor: 'pointer',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Radio checked={selectedAccess === a.value} value={selectedAccess} />
                <Typography>{a.label}</Typography>
              </Box>
            ))}
          </Box>
          <Box sx={{ ml: 'auto' }}>
            <Button fullWidth variant="contained" disabled={isAddDisabled}>
              Add wallet
            </Button>
          </Box>
        </Card>
      )}
      {walletRights.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <WalletsAccessTable walletRights={walletRights} />
        </Box>
      )}
    </Box>
  );
};
