// SPDX-License-Identifier: Apache-2.0
import { IdpLoginInfo } from 'neuchatel-proxy-api-ts-client';
import { WebStorageStateStore } from 'oidc-client-ts';
import { PropsWithChildren, useState, useEffect } from 'react';
import { AuthProvider as OidcAuthProvider } from 'react-oidc-context';
import { useNavigate } from 'react-router-dom';
import { IDP_INFO_KEY } from '../../utils/constants';

export const AuthProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [idpInfo, setIdpInfo] = useState<IdpLoginInfo | undefined>(undefined);
  const nav = useNavigate();

  useEffect(() => {
    const idpInfoJson = localStorage.getItem(IDP_INFO_KEY);
    if (idpInfoJson) {
      setIdpInfo(JSON.parse(idpInfoJson) as IdpLoginInfo);
    } else {
      nav('/login');
    }
  }, [nav]);

  const redirectUri = window.location.origin;

  // TODO: Dex can't give a custom scope, but do we need it to?
  // const scope = 'daml_ledger_api offline_access openid';
  const scope = 'offline_access openid';

  if (!idpInfo) {
    return <>Redirecting to login...</>;
  }

  return (
    <OidcAuthProvider
      automaticSilentRenew
      authority={idpInfo.authority}
      client_id={idpInfo.clientId}
      redirect_uri={redirectUri}
      userStore={new WebStorageStateStore({ store: window.localStorage })}
      scope={scope}
      extraQueryParams={{ audience: 'https://utility.canton.network' }}
      onSigninCallback={() => {
        window.history.replaceState({}, document.title, window.location.pathname);
      }}
    >
      {children}
    </OidcAuthProvider>
  );
};
