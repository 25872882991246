import { TransferRequestRowProps } from '../../components/TransferRequestRow';

export const mockTransferRequests: TransferRequestRowProps[] = [
  {
    type: 'transfer',
    date: 'July 29 2024',
    initiator: { name: 'jodie', address: 'xyz::abcduriewjfklsjfdlks9fdsafdsa320499890' },
    coSigners: ['abc:1234566', 'efg:3243'],
    recipient: { name: 'Taylor', address: 'xyz::abcduriewjfklsjfdlks93fdsfsa20499890' },
    amount: 340459034,
  },
];
