import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import CopyableText from '../../components/CopyableText';
import { CredentialsTable } from '../../components/CredentialsTable';
import { mockCredentials } from '../../tests/mocks/mockCredentials';

export const CredentialsPage: React.FC = () => {
  return (
    <Box sx={{ p: 4, dispay: 'flex', flexDirection: 'column' }}>
      <Typography fontWeight="bold" variant="h4">
        Credentials
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Typography>Wallet ID:</Typography>
        <CopyableText text=" e1a06f07-7121-4b21-a7c0-1603bda2a7d9" />
      </Box>
      <Divider sx={{ mt: 4, mb: 4 }} />
      <CredentialsTable rows={mockCredentials} />
    </Box>
  );
};
