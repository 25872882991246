import React from 'react';
import { Box, Card, CardActionArea, Typography } from '@mui/material';

interface AssetCardProps {
  name: string;
  value: number;
  symbol: string;
}
export const AssetCard: React.FC<AssetCardProps> = ({ name, value, symbol }) => {
  return (
    <Card elevation={0} variant="outlined">
      <CardActionArea
        sx={{
          p: 2,
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'flex-start',
          background: 'linear-gradient(311.42deg, #E0E8FF 4.97%, #FFFFFF 90.27%)',
        }}
      >
        <Box>
          <Typography variant="body2" color="textSecondary">
            {name}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 1 }}>
          <Typography fontWeight="bold">{value}</Typography>
          <Typography sx={{ ml: 1 }} fontWeight="bold">
            {symbol}
          </Typography>
        </Box>
      </CardActionArea>
    </Card>
  );
};
