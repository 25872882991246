import React from 'react';
import { Box, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ICredential } from '../types/types';
import CopyableText from './CopyableText';

export interface IColumn<IUserRow> {
  id: string;
  label?: string;
  minWidth?: number;
  element: (value: IUserRow) => JSX.Element;
  align: TableCellProps['align'];
}

const columns: IColumn<ICredential>[] = [
  {
    id: 'issuer',
    label: 'issuer',
    align: 'left',
    element: (props) => (
      <Box sx={{ maxWidth: '200px' }}>
        <Typography>{props.issuer.name}</Typography>
        <Typography sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
          {props.issuer.address}
        </Typography>
      </Box>
    ),
  },
  {
    id: 'claims',
    label: 'claims',
    align: 'left',
    element: (props) => (
      <Box>
        {props.claims.map((c) => (
          <Typography key={c}>{c}</Typography>
        ))}
      </Box>
    ),
  },
  {
    id: 'desc',
    label: 'description',
    align: 'left',
    element: (props) => <Typography variant="body2">{props.desc}</Typography>,
  },
  {
    id: 'operator',
    label: 'operator',
    align: 'left',
    element: (props) => (
      <Box sx={{ maxWidth: '200px' }}>
        <Typography>{props.issuer.name}</Typography>
        <Typography sx={{ textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden' }}>
          {props.issuer.address}
        </Typography>
      </Box>
    ),
  },
  {
    id: 'holder',
    label: 'holder',
    align: 'left',
    element: (props) => (
      <Box>
        <Typography>{props.issuer.name}</Typography>
        <Typography>{props.issuer.address}</Typography>
      </Box>
    ),
  },
  {
    id: 'id',
    label: 'ID',
    align: 'left',
    element: (props) => (
      <Box sx={{ maxWidth: '150px' }}>
        <CopyableText text={props.id} />
      </Box>
    ),
  },
];

interface CredentialsTableProps {
  rows: ICredential[];
}
export const CredentialsTable: React.FC<CredentialsTableProps> = ({ rows }) => (
  <TableContainer sx={{ maxHeight: '90vh' }}>
    <Table stickyHeader aria-label="sticky table" sx={{ borderCollapse: 'seperate' }}>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={String(column.id)}
              align={column.align}
              sx={{ minWidth: column.minWidth, textTransform: 'uppercase' }}
            >
              <Typography variant="body2" color="primary">
                {column.label}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody
        sx={{
          borderCollapse: 'collapse',
          border: '1px solid red',
          background: (theme) => theme.palette.grey[100],
        }}
      >
        {rows.map((row) => {
          return (
            <TableRow tabIndex={-1} key={row.id}>
              {columns.map((column) => {
                return <TableCell key={String(column.id)}>{column.element(row)}</TableCell>;
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
);
