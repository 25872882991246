import { useEffect, useRef } from 'react';
import { useAuth } from 'react-oidc-context';
import { Typography, Box } from '@mui/material';
import useUserState from '../../hooks/other/useUserState';
import { IDP_INFO_KEY } from '../../utils/constants';

export const Logout: React.FC = () => {
  const { removeLoggedInUser } = useUserState();
  const auth = useAuth();
  const hasLoggedOut = useRef(false);

  useEffect(() => {
    localStorage.removeItem(IDP_INFO_KEY);
    if (!hasLoggedOut.current) {
      hasLoggedOut.current = true;
      auth
        .signoutRedirect({
          id_token_hint: auth.user?.id_token,
          post_logout_redirect_uri: window.location.origin,
        })
        .catch(() => console.debug('There was an issue redirecting the user to logout.'))
        .finally(removeLoggedInUser);
    }
  }, [auth, removeLoggedInUser]);

  return (
    <Box sx={{ display: 'flex', padding: 2 }}>
      <Typography>Logging out...</Typography>
    </Box>
  );
};
