import React from 'react';
import styled from 'styled-components';

// ScrollableCell component
type ScrollableCellProps = {
  content: React.ReactNode;
};

// Styled div for the scrollable content
const ScrollableDiv = styled.div`
  white-space: nowrap;
  overflow-x: auto;
  max-width: 100%;
  display: block;

  /* Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(255, 255, 255, 0.3) rgba(30, 30, 30, 0.1);

  /* Chrome, Safari, Edge */
  &::-webkit-scrollbar {
    height: 4px; /* Thin scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.3); /* Lighter thumb for contrast */
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background: rgba(30, 30, 30, 0.1); /* Slightly lighter track */
  }
`;

const ScrollableCell: React.FC<ScrollableCellProps> = ({ content }) => {
  return <ScrollableDiv>{content}</ScrollableDiv>;
};

export default ScrollableCell;
