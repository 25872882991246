import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ChevronLeft, Close } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Toolbar,
  Typography,
} from '@mui/material';
import { WalletAccess } from '../../components/WalletAccess';

const roleOptions = ['User', 'Admin'];

export const UserDetails: React.FC = () => {
  const [selectedRole, setRole] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const onClose = () => {
    setOpen(false);
  };
  const onOpen = () => {
    setOpen(true);
  };
  const onSelectRole = (event: SelectChangeEvent) => {
    setRole(event.target.value);
  };
  const nav = useNavigate();
  const onBack = () => {
    nav(-1);
  };
  const { state } = useLocation() as {
    state: {
      username: string;
      email: string;
      walletRights: { walletId: string; rights: string; walletName?: string }[];
    };
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }}
    >
      <Box sx={{ mb: 4 }}>
        <Typography sx={{ mb: 2 }} fontWeight="bold" variant="h5">
          User Details
        </Typography>
        <Button sx={{ pl: 0 }} onClick={onBack} startIcon={<ChevronLeft />}>
          <Typography variant="caption">Back</Typography>
        </Button>
      </Box>
      <Box sx={{ mb: 4, display: 'flex', flexDirection: 'row' }}>
        <Avatar sx={{ mr: 2 }}>{state?.username?.[0]}</Avatar>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography>{state?.username}</Typography>
          <Typography variant="caption" color="textSecondary">
            {state?.email || 'No email'}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', width: 400 }}>
        <Box sx={{ mb: 6 }}>
          <Typography>Role</Typography>
          <Select
            displayEmpty
            renderValue={(value: string) => {
              if (!value) {
                return <Typography color="textSecondary">Select role</Typography>;
              }
              return value;
            }}
            onChange={onSelectRole}
            fullWidth
            value={selectedRole}
          >
            {roleOptions.map((r) => (
              <MenuItem value={r} key={r}>
                {r}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box sx={{ ml: 0 }}>
          <WalletAccess name={state?.username} walletRights={state?.walletRights} />

          <Button
            onClick={onOpen}
            sx={{ textTransform: 'capitalize', mt: 2 }}
            variant="outlined"
            color="error"
          >
            Remove from Organization
          </Button>
        </Box>
      </Box>
      <Dialog open={open} onClose={onClose}>
        <Toolbar disableGutters>
          <DialogTitle>Remove from Organization</DialogTitle>
          <IconButton onClick={onClose} sx={{ ml: 'auto', mr: 1 }}>
            <Close />
          </IconButton>
        </Toolbar>

        <DialogContent>
          <DialogContentText variant="caption">
            This action will remove {state?.username}&apos;s access to all wallets but in order to
            permanently remove them from the organization they need to be removed from your SSO
            provider first.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" variant="outlined" sx={{ textTransform: 'capitalize' }}>
            Remove from organization
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};
