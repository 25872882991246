import React from 'react';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Toolbar,
  Typography,
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

interface IWalletRights {
  walletId: string;
  rights: string;
  walletName?: string;
}
export interface IColumn {
  id: string;
  label?: string;
  minWidth?: number;
  element: (value: IWalletRights) => JSX.Element;
  align: TableCellProps['align'];
}

interface UserDetailButtonProps {
  walletId: string;
  walletName?: string;
}
const accessRights = ['Read-As', 'Act-As'];
const RemoveButton: React.FC<UserDetailButtonProps> = ({ walletId, walletName }) => {
  const [open, setOpen] = React.useState(false);
  const onClick = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onDelete = () => {
    console.log('delete', walletId);
  };

  return (
    <>
      <Button
        variant="outlined"
        size="small"
        sx={{ textTransform: 'capitalize' }}
        onClick={onClick}
        color="error"
      >
        Remove
      </Button>
      <Dialog onClose={onClose} open={open}>
        <Toolbar disableGutters>
          <DialogTitle>Remove {walletName}</DialogTitle>
          <IconButton sx={{ ml: 'auto', mr: 1 }} onClick={onClose}>
            <Close />
          </IconButton>
        </Toolbar>
        <DialogContent>
          <DialogContentText>
            <Typography>Are you sure you want to remove this wallet?</Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ mt: 1, mb: 1 }}>
          <Button
            sx={{ textTransform: 'capitalize' }}
            size="small"
            variant="outlined"
            color="error"
            onClick={onDelete}
          >
            Remove wallet
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
interface SelectAccessProps {
  currAccess: string;
}
const SelectAccess: React.FC<SelectAccessProps> = ({ currAccess }) => {
  const [selectedRole, setRole] = React.useState(currAccess);
  const onSelectRole = (event: SelectChangeEvent) => {
    setRole(event.target.value);
  };
  return (
    <Select
      displayEmpty
      sx={{ textTransform: 'capitalize' }}
      variant="outlined"
      size="small"
      renderValue={(value: string) => {
        if (!value) {
          return <Typography color="textSecondary">Select rights</Typography>;
        }
        return value;
      }}
      onChange={onSelectRole}
      fullWidth
      value={selectedRole}
    >
      {accessRights.map((r) => (
        <MenuItem value={r} key={r}>
          {r}
        </MenuItem>
      ))}
    </Select>
  );
};

const columns: IColumn[] = [
  {
    id: 'name',
    label: 'name',
    align: 'left',
    element: (props) => <Typography variant="body2">{props.walletName}</Typography>,
  },
  {
    id: 'access',
    label: 'access',
    align: 'left',
    element: (props) => <SelectAccess currAccess={props.rights} />,
  },
  {
    id: 'action',
    label: 'actions',
    align: 'right',
    element: (props) => (
      <Box sx={{ justifyContent: 'flex-end', display: 'flex' }}>
        <RemoveButton walletName={props.walletName} walletId={props.walletId} />
      </Box>
    ),
  },
];

interface WalletsAccessTableProps {
  walletRights: IWalletRights[];
}
export const WalletsAccessTable: React.FC<WalletsAccessTableProps> = ({ walletRights }) => (
  <TableContainer sx={{ maxHeight: '300px' }}>
    <Table stickyHeader aria-label="sticky table" sx={{ borderCollapse: 'seperate' }}>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={String(column.id)}
              align={column.align}
              sx={{ minWidth: column.minWidth, textTransform: 'uppercase' }}
            >
              <Typography variant="body2" color="primary">
                {column.label}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody
        sx={{
          borderCollapse: 'collapse',
          border: '1px solid red',
          background: (theme) => theme.palette.grey[100],
        }}
      >
        {walletRights?.map((row) => {
          return (
            <TableRow tabIndex={-1} key={row.walletId}>
              {columns.map((column) => {
                return <TableCell key={String(column.id)}>{column.element(row)}</TableCell>;
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
);
