import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { UsersTable } from '../../components/UsersTable';
import { useGetUsers } from '../../hooks/queries/investor/useGetUsers';
import { UserDetails } from './UserDetailsPage';

const MainUserPage: React.FC = () => {
  const { data } = useGetUsers();

  return (
    <>
      <Typography variant="h5" fontWeight="bold">
        Users
      </Typography>
      <UsersTable rows={data || []} />
    </>
  );
};

export const UsersPage: React.FC = () => {
  return (
    <Box sx={{ p: 4, pt: 6 }}>
      <Routes>
        <Route path="/" element={<MainUserPage />} />
        <Route path="/details/:username" element={<UserDetails />} />
      </Routes>
    </Box>
  );
};
