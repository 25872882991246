import React from 'react';
import { Box, Typography } from '@mui/material';
import { ActionsRequired } from '../../components/ActionsRequired';
import { NotificationsEmptyState } from '../../components/NotificationsEmptyState';

export const NotificationsPage: React.FC = () => {
  const hasNotifications = true;
  return (
    <Box sx={{ m: 4 }}>
      <Typography variant="h4" fontWeight="bold">
        Notifications
      </Typography>
      {!hasNotifications && <NotificationsEmptyState />}
      <ActionsRequired />
    </Box>
  );
};
