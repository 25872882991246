import React from 'react';
import { useAuth } from 'react-oidc-context';
import { Box, Typography } from '@mui/material';
import CopyableText from '../../components/CopyableText';

export const AccountPage: React.FC = () => {
  const auth = useAuth();
  return (
    <Box sx={{ p: 4, dispay: 'flex', flexDirection: 'column' }}>
      <Typography fontWeight="bold" variant="h4">
        Account
      </Typography>
      {import.meta.env.DEV && auth.user && (
        <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
          {auth.user.id_token && (
            <Box sx={{ display: 'flex', alignItems: 'center', height: '2em' }}>
              <Typography>Copy ID Token:</Typography>
              <CopyableText text={auth.user.id_token} hideText />
            </Box>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2em' }}>
            <Typography>Copy API Token:</Typography>
            <CopyableText text={auth.user.access_token} hideText />
          </Box>
        </Box>
      )}
    </Box>
  );
};
