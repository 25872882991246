import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { mockTransferRequests } from '../tests/mocks/mockTransferRequests';
import { TransferRequestRow, TransferRequestRowProps } from './TransferRequestRow';

const selectRowComponent = (data: TransferRequestRowProps) => {
  if (data.type === 'transfer') {
    const { type, date, initiator, coSigners, recipient, amount } = data;
    return (
      <TransferRequestRow
        type={type}
        date={date}
        initiator={initiator}
        coSigners={coSigners}
        recipient={recipient}
        amount={amount}
      />
    );
  }
  return null;
};

export const ActionsRequired: React.FC = () => {
  const nav = useNavigate();
  const goToNotifications = () => {
    nav('/notifications');
  };
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={{ textTransform: 'uppercase' }} fontWeight="bold">
          Actions Required
        </Typography>
        <Button onClick={goToNotifications} sx={{ ml: 'auto', textTransform: 'capitalize' }}>
          View all notifications
        </Button>
      </Box>
      {mockTransferRequests.map((data) => selectRowComponent(data))}
    </Box>
  );
};
