import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Typography } from '@mui/material';
import { CredentialsPage } from './CredentialsPage';
import { TransactionsPage } from './TransactionsPage';

export const WalletPage: React.FC = () => {
  return (
    <Routes>
      <Route path="/overview/*" element={<Typography>wallet overview</Typography>} />
      <Route path="/transactions/*" element={<TransactionsPage />} />
      <Route path="/credentials/*" element={<CredentialsPage />} />
    </Routes>
  );
};
