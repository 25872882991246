import { ITransaction } from '../../types/types';

export const mockTransactions: ITransaction[] = [
  {
    operator: { name: 'max', address: 'drw::123459013449090' },
    provider: { name: 'max', address: '13449090' },
    registrar: { name: 'max', address: '13449090' },
    sender: { name: 'max', address: 'drw::123459013449090' },
    receiver: { name: 'max', address: 'drw::123459013449090' },
    insutrmentIdentifier: { id: '10', source: '1234', scheme: '' },
    amount: 1000,
    reference: 'test amount',
    transactionId: '12345',
    date: 15000000,
    type: 'sent',
  },
  {
    operator: { name: 'max', address: 'drw::123459013449090' },
    provider: { name: 'max', address: '13449090' },
    registrar: { name: 'max', address: '13449090' },
    sender: { name: 'max', address: 'drw::123459013449090' },
    receiver: { name: 'max', address: 'drw::123459013449090' },
    insutrmentIdentifier: { id: '10', source: '1234', scheme: '' },
    amount: 1000,
    reference: 'test amount',
    transactionId: '12345',
    date: 15000000,
    type: 'sent',
  },
  {
    operator: { name: 'max', address: 'drw::123459013449090' },
    provider: { name: 'max', address: '13449090' },
    registrar: { name: 'max', address: '13449090' },
    sender: { name: 'max', address: 'drw::123459013449090' },
    receiver: { name: 'max', address: 'drw::123459013449090' },
    insutrmentIdentifier: { id: '10', source: '1234', scheme: '' },
    amount: 1000,
    reference: 'test amount',
    transactionId: '12345',
    date: 15000000,
    type: 'sent',
  },
  {
    operator: { name: 'max', address: 'drw::123459013449090' },
    provider: { name: 'max', address: '13449090' },
    registrar: { name: 'max', address: '13449090' },
    sender: { name: 'max', address: 'drw::123459013449090' },
    receiver: { name: 'max', address: 'drw::123459013449090' },
    insutrmentIdentifier: { id: '10', source: '1234', scheme: '' },
    amount: 1000,
    reference: 'test amount',
    transactionId: '12345',
    date: 15000000,
    type: 'sent',
  },
];
