import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined';
import LoginOutlinedIcon from '@mui/icons-material/LoginOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import {
  Box,
  Button,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import useUserState from '../hooks/other/useUserState';
import { mockWallets } from '../tests/mocks/mockWallets';

const drawerWidth = 240;
const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const top = [
  { label: 'Dashboard', to: '/', icon: <DashboardOutlinedIcon /> },
  {
    label: 'notifications',
    to: '/notifications',
    icon: <NotificationsNoneOutlinedIcon />,
  },
  { label: 'users', to: '/users', icon: <GroupOutlinedIcon /> },
];
const bottom = [
  { label: 'Account', to: '/account', icon: <AccountCircleOutlinedIcon /> },
  { label: 'Log out', to: '/logout', icon: <LoginOutlinedIcon /> },
];
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      },
    },
  ],
}));
interface ISidebarItem {
  label: string;
  to: string;
  icon: React.ReactNode;
  nestedItems?: { label: string; to: string }[];
  expandedItem?: string;
  onExpand?: (item: string) => void;
}
const SidebarItem: React.FC<ISidebarItem & { open: boolean }> = ({
  nestedItems,
  label,
  to,
  icon,
  open,
  expandedItem,
  onExpand,
}) => {
  const nav = useNavigate();
  const moreLessIcon = expandedItem === 'label' ? <ExpandLess /> : <ExpandMore />;
  const expandIcon = open ? moreLessIcon : null;
  return (
    <>
      <ListItem disablePadding key={label}>
        <ListItemButton
          onClick={
            nestedItems
              ? () => {
                  nav(to);
                  onExpand?.(label === expandedItem ? '' : label);
                }
              : () => nav(to)
          }
          sx={[
            {
              minHeight: 48,
              px: 2.5,
            },
            open
              ? {
                  justifyContent: 'initial',
                }
              : {
                  justifyContent: 'center',
                },
          ]}
        >
          <ListItemIcon
            sx={[
              {
                minWidth: 0,
                justifyContent: 'center',
              },
              open
                ? {
                    mr: 3,
                  }
                : {
                    mr: 'auto',
                  },
            ]}
          >
            {icon}
          </ListItemIcon>
          <ListItemText
            sx={[
              open
                ? {
                    opacity: 1,
                  }
                : {
                    opacity: 0,
                  },
              {
                textTransform: 'capitalize',
              },
            ]}
            primary={
              <Typography
                sx={{
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                }}
              >
                {label}
              </Typography>
            }
          />
          {nestedItems?.length ? expandIcon : null}
        </ListItemButton>
      </ListItem>
      <Collapse in={expandedItem === label} timeout="auto">
        <List component="div" disablePadding>
          {nestedItems?.map((c) => (
            <ListItemButton onClick={() => nav(`${c.to}`)} key={c.label} sx={{ pl: 9 }}>
              <ListItemText primary={c.label} />
            </ListItemButton>
          ))}
        </List>
      </Collapse>
    </>
  );
};
export const SidebarExpandable: React.FC = () => {
  const [open, setOpen] = React.useState(true);
  const [expandedItem, setExpandedItem] = React.useState('');
  const onExpand = (item: string) => {
    setExpandedItem(item);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const { logout } = useUserState();

  const handleDrawerClose = () => {
    setOpen(false);
    setExpandedItem('');
  };
  const topMenuItems = top.map((i) => {
    return <SidebarItem key={i.label} label={i.label} to={i.to} icon={i.icon} open={open} />;
  });
  const walletItems = mockWallets.map((w) => (
    <SidebarItem
      key={w.walletId}
      label={w.walletName}
      to={`/wallet/overview/${w.walletId}`}
      icon={<AccountBalanceWalletOutlinedIcon />}
      open={open}
      onExpand={onExpand}
      expandedItem={expandedItem}
      nestedItems={[
        { label: 'Overview', to: `/wallet/overview/${w.walletId}` },
        { label: 'Transactions', to: `/wallet/transactions/${w.walletId}` },
        { label: 'Credentials', to: `/wallet/Credentials/${w.walletId}` },
      ]}
    />
  ));
  const bottomMenuItems = bottom.map((i) => {
    return <SidebarItem key={i.label} label={i.label} to={i.to} icon={i.icon} open={open} />;
  });
  return (
    <Drawer variant="permanent" open={open}>
      <Toolbar sx={{ display: 'flex', justifyContent: 'center' }}>
        {open && <Typography fontWeight="bold">Neuchatel</Typography>}
        <IconButton
          onClick={open ? handleDrawerClose : handleDrawerOpen}
          sx={{ ml: open ? 'auto' : 0 }}
        >
          {open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
        </IconButton>
      </Toolbar>
      {topMenuItems}
      <Divider />
      {walletItems}
      <Divider />
      <Button onClick={logout}>Logout</Button>
      <Box sx={{ mt: 'auto' }}>{bottomMenuItems}</Box>
    </Drawer>
  );
};
