import React from 'react';
import { Box } from '@mui/material';
import { TransactionsTable } from '../../components/TransactionsTable';
import { mockTransactions } from '../../tests/mocks/mockTransactions';

export const TransactionsPage: React.FC = () => {
  return (
    <Box>
      <TransactionsTable transactions={mockTransactions} />
    </Box>
  );
};
