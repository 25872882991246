import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ITransaction } from '../types/types';
import CopyableText from './CopyableText';

export interface IColumn<ITransaction> {
  id: string;
  label?: string;
  minWidth?: number;
  element: (value: ITransaction) => JSX.Element;
  align: TableCellProps['align'];
}

const columns: IColumn<ITransaction>[] = [
  {
    id: 'Type',
    label: 'Type',
    align: 'left',
    element: (props) => <Typography>{props.type}</Typography>,
  },
  {
    id: 'date',
    label: 'date',
    align: 'left',
    element: (props) => (
      <Typography variant="body2">{new Date(props.date * 1000).toDateString()}</Typography>
    ),
  },
  {
    id: 'sender',
    label: 'sender',
    align: 'left',
    element: (props) => (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <CopyableText text={props.sender.address} />
      </Box>
    ),
  },
  {
    id: 'receiver',
    label: 'receiver',
    align: 'left',
    element: (props) => (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <CopyableText text={props.sender.address} />
      </Box>
    ),
  },
  {
    id: 'instrument',
    label: 'Instrument',
    align: 'left',
    element: (props) => <Typography variant="body2">{props.insutrmentIdentifier.id} </Typography>,
  },
  {
    id: 'amount',
    label: 'amount',
    align: 'left',
    element: (props) => <Typography variant="body2">{props.amount} </Typography>,
  },
];

interface TransactionsTableprops {
  transactions: ITransaction[];
}
export const TransactionsTable: React.FC<TransactionsTableprops> = ({ transactions: rows }) => (
  <TableContainer sx={{ maxHeight: '90vh' }}>
    <Table stickyHeader aria-label="sticky table" sx={{ borderCollapse: 'seperate' }}>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={String(column.id)}
              align={column.align}
              sx={{ minWidth: column.minWidth, textTransform: 'uppercase' }}
            >
              <Typography variant="body2" color="primary">
                {column.label}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody
        sx={{
          borderCollapse: 'collapse',
          border: '1px solid red',
          background: (theme) => theme.palette.grey[100],
        }}
      >
        {rows.map((row) => {
          return (
            <TableRow tabIndex={-1} key={row.transactionId}>
              {columns.map((column) => {
                return <TableCell key={String(column.id)}>{column.element(row)}</TableCell>;
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
    <Button sx={{ textTransform: 'capitalize' }} fullWidth>
      Load More
    </Button>
  </TableContainer>
);
