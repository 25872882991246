import React from 'react';
import { Box, Typography } from '@mui/material';
import { mockTransactions } from '../tests/mocks/mockTransactions';
import { TransactionsTable } from './TransactionsTable';

export const RecentTransactions: React.FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', mt: 4 }}>
      <Typography fontWeight="bold" sx={{ textTransform: 'uppercase' }}>
        Recent Transactions
      </Typography>
      <TransactionsTable transactions={mockTransactions} />
    </Box>
  );
};
