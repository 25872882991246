import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronLeft } from '@mui/icons-material';
import { AppBar, Box, Card, IconButton, Switch, Toolbar, Typography } from '@mui/material';
import { useLocalStorage } from '../hooks/other/useLocalStorage';

export const AppSettings: React.FC = () => {
  const { localStorageState, setLocalStorageState } = useLocalStorage();
  console.log('LOAL', localStorageState);

  const onToggle = () => {
    setLocalStorageState('pollingEnabled', `${!localStorageState?.pollingEnabled}`);
  };
  const { pollingEnabled } = localStorageState;
  console.log('hi', pollingEnabled);
  const nav = useNavigate();
  const onBack = () => {
    nav(-1);
  };
  return (
    <Box sx={{ p: 3 }}>
      <AppBar>
        <Toolbar>
          <IconButton onClick={onBack}>
            <ChevronLeft />
          </IconButton>
          <Typography variant="h5">settings</Typography>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', flex: 1 }}>
        <Card sx={{ width: '50%', p: 1, display: 'flex', alignItems: 'center' }} variant="outlined">
          <Box>
            <Typography fontWeight="bold">Network Polling</Typography>
            <Typography variant="body2">
              If toggled off, manual refreshing will be required to see updated data.
            </Typography>
          </Box>
          <Switch sx={{ ml: 'auto' }} checked={pollingEnabled} onChange={onToggle} />
        </Card>
      </Box>
    </Box>
  );
};
