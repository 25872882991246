import { Outlet, useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import { SidebarExpandable } from '../components/SidebarExpandable';
import useUserState from '../hooks/other/useUserState';
import { IDP_INFO_KEY } from '../utils/constants';

export const Layout: React.FC = () => {
  const { loggedInUser, login } = useUserState();
  const idpInfo = localStorage.getItem(IDP_INFO_KEY);
  const nav = useNavigate();

  if (!loggedInUser && idpInfo) {
    login('enter');
  } else if (!loggedInUser) {
    nav('/login');
  }

  if (!loggedInUser) {
    return (
      <Box sx={{ display: 'flex', padding: 2 }}>
        <Typography>Handling login...</Typography>
      </Box>
    );
  }
  return (
    <Box sx={{ display: 'flex' }}>
      <SidebarExpandable />
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Outlet />
      </Box>
    </Box>
  );
};
