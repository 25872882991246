import { ICredential } from '../../types/types';

export const mockCredentials: ICredential[] = [
  {
    issuer: {
      name: 'Hasnode',
      address: 'xyz::abc1349385849302854908',
    },
    claims: ['Wallet ABC', 'hasRegistryRole'],
    desc: 'this is a free credential',
    operator: {
      name: 'Something else',
      address: 'xyz::abcddfdsj',
    },
    holder: {
      name: 'holder',
      address: 'abcdef',
    },
    id: 'xtzd:ee4039589038590850938kjfr',
  },
  {
    issuer: {
      name: 'Canton',
      address: 'xyz::abc1384385093850944938',
    },
    claims: ['Wallet ABC'],
    desc: 'this is a free credential',
    operator: {
      name: 'Something else',
      address: 'xyz::abcdfsljflsajfldajslf',
    },
    holder: {
      name: 'holder',
      address: 'abcdef',
    },
    id: 'extralongtexttoseewhtaoverlfowlooksike',
  },
];
