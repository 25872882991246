import { User } from 'neuchatel-proxy-api-ts-client';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ChevronRight } from '@mui/icons-material';
import { Avatar, Box, Button, Typography } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { TableCellProps } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

export interface IColumn<User> {
  id: string;
  label?: string;
  minWidth?: number;
  element: (value: User) => JSX.Element;
  align: TableCellProps['align'];
}
interface NameProps {
  name: string;
  email: string;
}
const Name: React.FC<NameProps> = ({ name, email }) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Avatar sx={{ mr: 1 }}>{name[0]}</Avatar>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography variant="body2">{name}</Typography>
        <Typography variant="caption" color="textSecondary">
          {email}
        </Typography>
      </Box>
    </Box>
  );
};

interface UserDetailButtonProps {
  username: string;
  email: string;
  walletRights: { walletId: string; walletName?: string; rights: string }[];
}
const UserDetailButton: React.FC<UserDetailButtonProps> = ({ walletRights, username, email }) => {
  const nav = useNavigate();
  const onClick = () => {
    nav(`details/${username}`, { state: { username, email, walletRights } });
  };
  return (
    <Button
      endIcon={<ChevronRight />}
      variant="outlined"
      size="small"
      sx={{ textTransform: 'capitalize' }}
      onClick={onClick}
    >
      View Details
    </Button>
  );
};

const columns: IColumn<User>[] = [
  {
    id: 'name',
    label: 'name',
    align: 'left',
    element: (props) => <Name name={props.name} email={props.email} />,
  },
  {
    id: 'role',
    label: 'role',
    align: 'left',
    element: (props) => <Typography variant="body2">{props.role}</Typography>,
  },
  {
    id: 'wallets',
    label: 'wallets',
    align: 'left',
    element: (props) => <Typography variant="body2">{props.walletRights.length} Wallet</Typography>,
  },
  {
    id: 'action',
    align: 'right',
    element: (props: User) => (
      <Box sx={{ justifyContent: 'flex-end', display: 'flex' }}>
        <UserDetailButton
          email={props.email}
          username={props.name}
          walletRights={props.walletRights}
        />
      </Box>
    ),
  },
];

interface UserTableProps {
  rows: User[];
}
export const UsersTable: React.FC<UserTableProps> = ({ rows }) => (
  <TableContainer sx={{ maxHeight: '90vh' }}>
    <Table stickyHeader aria-label="sticky table" sx={{ borderCollapse: 'seperate' }}>
      <TableHead>
        <TableRow>
          {columns.map((column) => (
            <TableCell
              key={String(column.id)}
              align={column.align}
              sx={{ minWidth: column.minWidth, textTransform: 'uppercase' }}
            >
              <Typography variant="body2" color="primary">
                {column.label}
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>

      <TableBody
        sx={{
          borderCollapse: 'collapse',
          border: '1px solid red',
          background: (theme) => theme.palette.grey[100],
        }}
      >
        {rows.map((row) => {
          return (
            <TableRow tabIndex={-1} key={row.name}>
              {columns.map((column) => {
                return <TableCell key={String(column.id)}>{column.element(row)}</TableCell>;
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  </TableContainer>
);
