import { createTheme, ThemeOptions } from '@mui/material';
import broadridgeLogoBlue from '../assets/broadridgeLogoBlue.svg';
import broadridgeLogoWhite from '../assets/broadridgeLogoWhite.svg';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
    cardText?: {
      color?: string;
    };
    cardTitle?: {
      color?: string;
    };
    appbar?: {
      headerIcon?: {
        color?: string;
      };
    };
    sidebar: {
      text: {
        color: string;
      };
      active: {
        color: string;
      };
    };
    headerButtonText: {
      primary: 'white';
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
    cardText?: {
      color?: string;
    };
    cardTitle?: {
      color?: string;
    };
    appbar?: {
      headerIcon: {
        color: string;
      };
    };
    sidebar?: {
      text?: {
        color: string;
      };
      active?: {
        color?: string;
      };
    };
    headerButtonText?: {
      primary?: string;
    };
  }
}
type Branding = {
  loginImage: JSX.Element;
  loginLogo: JSX.Element;
  headerLogo: JSX.Element;
  companyLogo: JSX.Element;
  options: ThemeOptions;
};
export const broadridge: Branding = {
  loginImage: (
    <img
      height="100px"
      alt=""
      src={broadridgeLogoBlue}
      style={{ display: 'inline-block', zIndex: 0 }}
    />
  ),
  loginLogo: <img height="100px" alt="" src={broadridgeLogoBlue} />,
  headerLogo: <img alt="" src={broadridgeLogoWhite} height="50px" />,
  companyLogo: <img alt="" src={broadridgeLogoWhite} height="50px" />,
  options: {
    cardTitle: {
      color: 'white',
    },
    appbar: {
      headerIcon: {
        color: '#00a3d6',
      },
    },
    cardText: {
      color: 'white',
    },
    sidebar: {
      active: {
        color: '#FAFAFA',
      },
    },
    headerButtonText: {
      primary: 'red',
    },
    palette: {
      mode: 'light',
      primary: { main: '#00578E' }, // #a6f6ff #00345f #002856 #6575f1
      secondary: { main: '#00a3d6' }, // #002856 #6575f1
      background: {
        default: '#FAFAFA', // #232F40
        paper: '#FAFAFA', // "#364963"
      },
      text: {
        primary: '#000',
      },
    },
    typography: {
      allVariants: {
        fontFamily: "'Lato', sans-serif;",
      },
    },
    components: {
      MuiTextField: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiDrawer: {
        styleOverrides: {
          paper: {
            width: 200,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            zIndex: 0,
          },
          root: {
            width: 200,
            flexShrink: 0,
            whiteSpace: 'nowrap',
            zIndex: 0,
            // backgroundColor: '#00578E',
            // border: 0,
          },
        },
      },
      MuiSelect: {
        defaultProps: {
          variant: 'standard',
        },
      },
      MuiStepLabel: {
        styleOverrides: {
          labelContainer: {
            color: '#9e9e9e',
          },
        },
      },
      MuiAutocomplete: {
        styleOverrides: {
          root: {
            label: {
              color: '#9e9e9e',
            },
          },
        },
      },
    },
  },
};

export const broadridgeTheme = createTheme(broadridge.options);
